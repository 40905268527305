<template>
  <div>
    <div class="content" id="pdfDom">
      <div class="main">
        <div class="home_page">
          <div style="font-size: 80px; text-align: center; margin-top: 250px">
            心理健康测评
          </div>
          <div style="font-size: 60px; text-align: center; margin-top: 30px">
            测评报告
          </div>
          <div style="font-size: 30px; text-align: center; margin-top: 150px">
            原著：[美]耶鲁大学心理系
          </div>
          <div style="
              font-size: 20px;
              text-align: center;
              margin-top: 200px;
              margin-bottom: 50px;
            ">
            报告接收人：{{ data.test_name }}
          </div>
        </div>
        <!--这里是分页	-->
        <DIV id="page1" style="page-break-after: always"></DIV>
        <!--分页结束-->
      </div>
      <div class="main">
        <div class="body">
          <div class="border">
            <div class="title">目录</div>
            <table width="100%" style="
                margin-top: 300px;
                margin-bottom: 280px;
                padding: 40px;
                line-height: 50px;
              ">
              <tbody>
                <tr>
                  <td class="line">
                    <div class="l b">SCL90测试结果</div>
                    <div class="r">1</div>
                  </td>
                </tr>
                <tr>
                  <td class="line">
                    <div class="l b">和人群平均分比较</div>
                    <div class="r">2</div>
                  </td>
                </tr>
                <tr>
                  <td class="line">
                    <div class="l">SCL90得分及描述</div>
                    <div class="r">2</div>
                  </td>
                </tr>

                <tr>
                  <td class="line">
                    <div class="l b">后记</div>
                    <div class="r">3</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!--这里是分页	-->
          <DIV id="page1" style="page-break-after: always"></DIV>
          <!--分页结束-->
        </div>
      </div>
      <div class="main">
        <div class="body">
          <div class="border">
            <div class="title">情商是什么</div>
            <div class="txt">
              <table style="width: 100%" class="mytab">
                <tbody class="tables">
                  <tr>
                    <td colspan="2" style="border-bottom: none">
                      你的总分为190.00分。总均分为2.00分
                      。你的标准分为238.00分。<span style="color: #f00">测试者有很明显的心理问题，可求助于心理咨询。</span>
                    </td>
                  </tr>
                  <tr>
                    <td width="100" style="white-space: nowrap">名称</td>
                    <td>平均分</td>
                    <td>得分</td>
                    <td>备注</td>
                  </tr>
                  <tr v-for="(item, i) in tests" :key="i">
                    <td width="100" style="white-space: nowrap">
                      {{ item.Explain }}
                    </td>
                    <td>
                      {{ item.Score }}
                    </td>
                    <td>
                      {{ item.Avg }}
                    </td>
                    <td>
                      {{ item.Remark }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!--这里是分页	-->
          <DIV id="page1" style="page-break-after: always"></DIV>
          <!--分页结束-->
        </div>
      </div>
      <div class="main">
        <div class="body">
          <div class="border">
            <div class="title">后记</div>
            <div class="txt">
              <br /><br />
              <br /><br />
              <br /><br />

              (1)本报告书内容是依当事人的问卷回答产生的结果。其内容的真实性无法保证。而是需要与当事人进一步的沟通。
              <br /><br />
              (2)本内容运用的领域是针对个人的行为、能力、价值及管理发展上，组织管理者不能视此报告
              作为唯一的评价依据，且本报告不负任何形式的法律问题。<br /><br /><br /><br /><br />
            </div>
          </div>
          <!--这里是分页	-->
          <DIV id="page1" style="page-break-after: always"></DIV>
          <!--分页结束-->
        </div>
      </div>
    </div>
    <div class="print" @click="doPrint()">
      <el-button type="primary" icon="el-icon-download">下载</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      data: [],
      tests: [{}, {}, {}],
    };
  },

  mounted() {
    this.ReportId = this.$route.query.id;
    this.$fecth
      .post("Appraisal/getResults", {
        id: this.ReportId,
        url: "http://www.apesk.com/lee/json/SCL-90/scl90_report_admin_json.asp",
      })
      .then((res) => {
        console.log(res);
        this.tests = res.Dimension;
        this.data = res;
      });
  },
  methods: {
    doPrint() {
      window.pageYoffset = 0;
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
      this.getPdf("#pdfDom");
    },
  },
};
</script>

<style scoped lang='less'>
.content {
  background: white;
  max-width: 800px;
  margin: 0 auto;
}
.main {
  .home_page {
    background-image: linear-gradient(#ff5357, #ff5357);
    color: white;
    overflow: hidden;
    margin-bottom: 30px;
  }
  .body {
    padding: 20px;
  }
  .border {
    padding: 20px;
    border: 1px solid #ff5357;
    position: relative;
    .title {
      position: absolute;
      top: -23px;
      left: 276px;
      height: 41px;
      width: 200px;
      background: #ff5357;
      text-align: center;
      line-height: 40px;
      color: white;
    }
  }

  .tables {
    border: 1px solid #ff5350;
  }

  .line div {
    padding: 6px;
    font-weight: bold;
    background-color: #ffffff;
  }
  .l {
    float: left;
  }
  .r {
    float: right;
    margin-bottom: 0px;
  }
}
.txt {
  line-height: 30px;
}
#chart {
  width: 500px;
  height: 500px;
  margin: 150px auto 0;
}
</style>
